.grid {
    max-width:($site-width + 20px);
    margin:0 ($site-margin-mobile - 10px);
    // transition:all 0.5s $easeOutQuad;

    @include breakpoint("min-width:600px") {
        margin:0 ($site-margin-mobile - 5px);
    }
    @include breakpoint(tablet-v) {
        margin:0 ($site-margin - 5px);
    }
    @include breakpoint(desktop-l) {
        margin:auto;
    }


    img {
        width:100%;
        // height:auto;
        // float:left;
    }
}

.grid__sizer {
    width:100%;
}
.grid__content {
    padding:10px;
    transition:opacity 0.5s $easeOutQuad;
}
.grid__content:after {
    content:" ";
    display:table;
    clear:left;
}
.grid__content-inner-wrap {
    position:relative;
    overflow:hidden;
    transition:opacity 0.5s $easeOutQuad;
}
.grid__caption {
    @include font(body-bold, 12px);
    color:white;
    position:absolute;
    box-sizing: border-box;
    width:100%;
    padding:16px 60px 16px 16px;
    bottom:0;
    background:rgba(0,0,0,0.7);
    display:none;   // Hide initially...

    p {
        margin:0;
    }

    @include breakpoint(desktop) {
        @include font-size(14px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(18px);
    }
}
.grid__caption-toggle {
    position:absolute;
    bottom:12px;
    right:12px;
    background:white;
    border-radius:50%;
    width:34px;
    height:34px;
    text-align:center;
    cursor:pointer;
    transition:all 0.3s $easeOutQuad;
    box-shadow: #333 0 0 0;

    &:hover {
        box-shadow: #666 1px 1px 6px;
    }

    .icon-toggle-arrow-up {
        @include font-size(14px);
        display:inline-block;
        margin-top:7px;
        margin-left:-3px;
    }

    &.is-active {
        .icon-toggle-arrow-up:before {
            content:"\e807";
        }
    }
}
.grid__video-link {
    position:absolute;
    left:50%;
    top:50%;
    width:70px;
    height:70px;
    // actual box dimensions end up being 98px
    margin-top:-49px;
    margin-left:-49px;

    .icon-play-icon {
        @include font-size(70px);
    }
    a {
        color:white;
        text-shadow: #333 0 0 10px;
        opacity:.9;
        transition:all 0.5s $easeOutQuad;
        text-decoration:none;
    }
    a:hover {
        text-shadow: #333 5px 5px 10px;
        opacity:1;
    }
}
.grid__item--small {
    width:100%;
}
.grid__item--medium {
    width:100%;
}
.grid__item--large {
    width:100%;
}
.grid__item--x-large {
    width:100%;
}

@media screen and (min-width: 600px) {
    .grid__sizer {
        width:10%;
    }

    .grid__item--small {
        width:29.5%;
    }
    .grid__item--small .grid__content {
        padding:2.6% 0% 2.6% 2.2%;
    }
    .grid__item--small.grid__item--land-port-layout .grid__content {
        padding:3.1% 2.2% 3.4% 0%;
    }
    .grid__item--medium {
        width:49.25%;
    }
    .grid__item--medium .grid__content {
        padding:1.9% .82% 1.9% 1.42%;
    }
    .grid__item--large {
        width:69.0%;
    }
    .grid__item--large .grid__content {
        padding:1.25% 0% 1.25% 2.4%;
    }
    .grid__item--large.grid__item--land-port-layout .grid__content {
        padding:1.25% 1.3% 1.25% 1.1%;
    }
    .grid__item--x-large .grid__content {
        padding:1.1% 1.1% 1.1% .6%;
    }


}

@media screen and (min-width: 600px) and (max-width: 900px) {
    // Special sizing for tablets
    .grid--tablet {
        .grid__item--small .grid__content {
            padding:2.6% 0% 2.6% 2.4%;
        }
        .grid__item--small.grid__item--land-port-layout .grid__content {
            padding:3.0% 2.2% 3.0% 1%;
        }
        .grid__item--x-large .grid__content {
            padding:1.1% 2.1% 1.1% .6%;
        }
    }
}