.branding-bar {
    display:none;
}


.home-menu {
    text-align:center;
}
.home-menu__list {
    margin:0;
    padding:0;
    list-style:none;
}
.home-menu__item {
    margin:.8em .6em;
    padding:0;
    display:inline-block;

    @include breakpoint(mobile-h) {
        margin:.8em .8em;
    }
    @include breakpoint(tablet-v) {
        margin:.8em 1.8em;
    }
}
.home-menu__link {
    @include font-size(20px);
    @include font(script-regular);
    color:white;
    text-shadow: 1px 2px 3px rgba(30, 30, 30, 1);
    text-transform:uppercase;
    letter-spacing: .2em;
    padding-left:2px;
    padding-right:2px;

    @include breakpoint(mobile-h) {
        @include font-size(28px);
    }
    @include breakpoint(tablet-v) {
        @include font-size(42px);
    }

    &:hover {
        color:$highlight;
    }
}