// Use for site width assignments throughout
$site-width: 1020px;
$site-width-padded: 1220px;
$site-margin: 100px;
$site-margin-mobile: 25px;

// Font stacks
$body-font-family: 'Karla', sans-serif;
$body-font-regular-weight: 400;
$body-font-bold-weight: 700;
$icon-font: 'icon-font';
$script-font-family: 'Colors-Of-Autumn', 'Brush Script MT', cursive;

// colors
$dark-gray: #1e1d1e;
$highlight: #688097;


// General Animation Values
$easeOutQuad: cubic-bezier(.17,.84,.44,1);