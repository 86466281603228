.content {
    padding-bottom:50px;
}
.property-description {
    @extend %container;
    @include font-size(20px);

    @include breakpoint(tablet-v) {
        @include font-size(28px);
    }

    transition:opacity 0.5s $easeOutQuad;
}