.main-menu {
    @extend %container;

    height:34px;
    overflow:hidden;
}
.main-menu__home {
    display:inline-block;
    color:white;
    @include font-size(20px);
    padding-top:4px;
    margin-left:-3px;

    &:hover {
        color:$highlight;
    }
}
.main-menu__list {
    margin:0;
    padding:0;
    float:right;

    @include breakpoint("min-width:600px") {
        margin:0 3px 0;
    }
}
.main-menu__item {
    padding:5px 0 2px 8px;
    display:inline-block;

    &.is-active {
        .main-menu__link {
            color:$highlight;
        }
    }

    @include breakpoint(mobile-h) {
        padding:5px 0 2px 18px;
    }
}
.main-menu__link {
    color:white;
    text-decoration: none;
    text-transform:uppercase;
    letter-spacing: .2em;
    padding-left:2px;
    padding-right:2px;
    @include font(script-regular);
    @include font-size(11px);

    &:hover {
        color:$highlight;
    }

    @include breakpoint(mobile-h) {
        @include font-size(12px);
    }
}