body {
    @include font(body-regular);
    color:black;
}
img {
    max-width:100%;
}
a {
    color:$highlight;
    text-decoration:none;
    transition:color 0.2s $easeOutQuad;
}
a:hover {
    color:black;
}