
.hero-image-container {
    position: relative;
    overflow:hidden;

    @include breakpoint("min-width:1280px") {
        // Once we hit full width of design layout, we want to vert crop
        // and center the image
        height:520px;
        margin-left: auto;
        margin-right: auto;
    }

    @include breakpoint("min-width:1800px") {
        // Try to be sure we don't cut off any heads
        height:620px;
    }
}
.hero-image {
    width: 100%;

    // Smaller screen sizes (< 1280) we rely on the image itself
    // to set the size/height of the container.

    @include breakpoint("min-width:1280px") {
        // Once we hit full width of design layout, we want to vert crop
        // and center the image
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    img {
        width:100%;
    }
}
.team-main-content {
    @extend %container;

    @include font-size(20px);
    margin-top:2em;

    @include breakpoint(tablet-v) {
        @include font-size(28px);
    }
}
.team-slider-container {
    max-width:($site-width + 20px);
    margin:2em ($site-margin-mobile - 10px);
    transition:all 0.5s $easeOutQuad;

    @include breakpoint(tablet-v) {
        margin:3em ($site-margin - 10px);
    }
    @include breakpoint(desktop-l) {
        margin:3em auto;
    }
}
.team-slider {
    .slick-arrow {
        // position:absolute;
        z-index: 10;
        width:20px;
        height:25px;
        color:black;
        overflow:hidden;


        &:hover {
            &:before {
                color:$highlight;
            }
        }

        @include breakpoint(tablet-v) {
            width:60px;
            height:60px;
        }
    }
    .slick-prev:before, .slick-next:before {
        font-size:25px;
        opacity:1;
        transition:all 0.2s $easeOutQuad;

        @include breakpoint(tablet-v) {
            font-size:58px;
        }
    }
    .slick-prev {
        left:-15px;
        &:before {
            content: '\e808';
        }

        @include breakpoint(tablet-v) {
            left:-68px;
            &:before {
                content: '\e802';
            }
        }

    }
    .slick-next {
        right:-15px;
        &:before {
            content: '\e809';
        }

        @include breakpoint(tablet-v) {
            right:-68px;
            &:before {
                content: '\e803';
            }
        }
    }
}
.team-slider__item {
    padding:10px;
    text-align:center;

    // Respond to rollovers
    &.is-hovering {
        .team-slider__info-wrap {
            opacity:1;
        }
        .team-slider__photo {
            transform: scale(1.1);
        }
    }
}
.team-slider__content {
    position:relative;
    display:inline-block;
    margin:auto;
    overflow:hidden;

    img {
        width:100%;
    }

}

.team-slider__photo {
    transition:all 0.5s $easeOutQuad;
}
.team-slider__info-wrap {
    position:absolute;
    opacity:0;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.8);
    transition:all 0.5s $easeOutQuad;
}
.team-slider__info {
    color:white;
    position:absolute;
    top:50%;
    margin-top:-32px;
    width:100%;
    line-height:1.3;
}
.team-slider__name {
    @include font-size(26px);
    transition:all 0.5s $easeOutQuad;

    @include breakpoint(tablet-v) {
        @include font-size(30px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(34px);
    }
}
.team-slider__title {
    @include font-size(13px);
    transition:all 0.5s $easeOutQuad;
    text-transform:uppercase;

    @include breakpoint(mobile-h) {
        @include font-size(14px);
    }
    @include breakpoint(tablet-v) {
        @include font-size(15px);
    }
    @include breakpoint(desktop-l) {
        @include font-size(16px);
    }
}

.contact {
    @extend %container;

    margin-bottom:3em;
}
.contact__label {
    @include font-size(18px);
    @include font(body-bold);
    text-transform:uppercase;
    margin-bottom:.5em;

    &:after {
        display:block;
        content:" ";
        font-size:0;
        width:80px;
        border-bottom:4px solid $highlight;
        padding-bottom:10px;
    }
    @include breakpoint (tablet-v) {
        @include font-size(24px);
    }
}
.contact__list {
    margin:0;
    padding:0;
    list-style:none;
}
.contact__item {
    @include font-size(18px);
    line-height:1.1;
    padding-bottom:.3em;

    @include breakpoint (mobile-h) {
        &.contact__item--phone {
            float:left;
        }
        &.contact__item--email {
            float:left;
            &:before {
                padding-left:12px;
                padding-right:12px;
                content:"|";
            }
        }
    }

    @include breakpoint (tablet-v) {
        @include font-size(24px);
    }

    @include breakpoint(desktop) {
        float:left;

        + .contact__item {
            &:before {
                padding-left:12px;
                padding-right:12px;
                content:"|";
            }
        }
    }
}
