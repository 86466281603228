
// For injecting breakpoint specifications into definitions.  Can supply
// one of the preset sizes, or pass a query rule as the parameter.
//
// Usage:
// @include breakpoint(mobile-v) { background: red; }
//   - or -
// @include breakpoint("min-width:600px") { color: blue; }

@mixin breakpoint($break) {
    @if $break == "mobile-v" {
        @media (min-width: 320px) { @content; }
    } @else if $break == "mobile-h" {
        @media (min-width: 480px) { @content; }
    } @else if $break == "tablet-v" {
        @media (min-width: 768px) { @content; }
    } @else if $break == "tablet-h" {
        @media (min-width: 1024px) and (orientation: landscape) { @content; }
    } @else if $break == "desktop" {
        @media (min-width: 1024px) { @content; }
    } @else if $break == "desktop-l" {
        @media (min-width: 1220px) { @content; }
    } @else if $break == "desktop-xl" {
        @media (min-width: 1400px) { @content; }
    } @else {
        @media ($break) { @content; }
    }
}



// For adding font sizes using 'rem' units.  Value should be supplied
// in 'px' format, and the rem value will be calculated, with a px
// fallback included.
//
//  Usage:
//  @include font-size(12px)

@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}

// For adding common, site-specific font values
//
//  Usage:
//
//  @include font(body-regular, 12px)
//    OR
//  @include font(body-bold)

@mixin font($font, $size: null) {
    @if $font == "body-regular" {
        font-family: $body-font-family;
        font-weight: $body-font-regular-weight;
    } @else if $font == "body-bold" {
        font-family: $body-font-family;
        font-weight: $body-font-bold-weight;
    } @else if $font == "script-regular" {
        font-family: $script-font-family;
        font-weight: normal;
    } @else {
        font-family: $font;
        font-weight:normal;
    }

    @if ($size) {
        @include font-size($size);
    }
}