
// Placeholder (%) definitions

// Use to apply clearfix behavior to a parent element containing
// floats (keeps them properly contained)
//
// Usage:
// .container-with-floated-children {
//      @extend %clearfix;
// }

%clearfix {
    *zoom: 1;
    &:before, &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// Use to set an outer container, which incorporates margin space
// for side scroller arrows
// Usage:
// .container-element {
//      @extend %container;
// }
%container {
    max-width:$site-width;
    margin:0 $site-margin-mobile;
    // transition:all 0.5s $easeOutQuad;

    @include breakpoint(tablet-v) {
        margin:0 $site-margin;
    }

    @include breakpoint(desktop-l) {
        margin:auto;
    }
}
