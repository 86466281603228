
.gallery-loader {
    width:24px;
    margin-left:-12px;
    left:50%;
    position:absolute;
    transition:opacity 0.3s $easeOutQuad;

    i {
        font-size:24px;
    }
}
.gallery__next {
    @extend %container;

    opacity:1;
    text-align:right;
    padding-top:8px;
    transition:opacity 2.3s $easeOutQuad;
}
.gallery__next-link {
    @include font-size(20px);
    color:black;
    text-decoration:none;
    transition:color 0.2s $easeOutQuad;

    &:hover {
        color:$highlight;
    }
    &:after {
        font-family:$icon-font;
        @include font-size(28px);
        content:"\e803";
        padding-left:8px;
        position:relative;
        top:4px;
    }

    @include breakpoint(tablet-v) {
        @include font-size(28px);

        &:after {
            @include font-size(36px);
        }
    }
}