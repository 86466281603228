
// Slick-carousel
$slick-font-path: "../fonts/" !default;
$slick-font-family: "icon-font" !default;
$slick-loader-path: "../img/" !default;
$slick-arrow-color: black !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\e802" !default;
$slick-next-character: "\e803" !default;
$slick-dot-character: "\e801" !default;
$slick-dot-size: 8px !default;
$slick-opacity-default: 0.65 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

.slick-dots li.slick-active button::before {
    content:"\e800";
}
.slick-prev::before, .slick-next::before {
    transition:opacity 0.15s;
}