.hero-slider-wrap {
    position:relative;
}
.hero-slider {
    background:black;

    .slick-arrow {
        position:absolute;
        z-index: 10;
        width:30px;
        height:30px;

        @include breakpoint(tablet-v) {
            width:50px;
            height:50px;
        }
    }
    .slick-prev:before, .slick-next:before {
        font-size:30px;
        color:white;

        @include breakpoint(tablet-v) {
            font-size:50px;
        }
    }
    .slick-prev {
        left:10px;
        @include breakpoint(tablet-v) {
            left:20px;
        }
    }
    .slick-next {
        right:10px;
        @include breakpoint(tablet-v) {
            right:20px;
        }
    }

    .slick-dots {
        bottom:6px;
    }
    .slick-dots li {
        margin:0;
        width:14px;
        height:14px;
    }
    .slick-dots li button:before {
        color:white;
    }
}
.hero-slider__item {
    position: relative;
    overflow:hidden;
    margin-left: auto;
    margin-right: auto;
    background-size:cover;
    background-position:50% 50%;
    background-repeat:no-repeat;
}
.hero-slider__overlay {
    position:absolute;
    display:block;
    bottom:0;
    left:0;
    right:0;
    height:120px;
    width:100%;
    background:url('../img/hero-overlay.png') repeat-x;
}
.hero-slider__title {
    @include font-size(18px);
    position:absolute;
    width:100%;
    text-align: center;
    color:white;
    bottom:30px;

    @include breakpoint(mobile-h) {
        @include font-size(24px);
    }
    @include breakpoint(tablet-v) {
        @include font-size(30px);
    }
}
.hero-slider__location {
    @include font-size(12px);
    text-transform:uppercase;
    padding-left:5px;

    @include breakpoint(mobile-h) {
        @include font-size(14px);
    }
    @include breakpoint(tablet-v) {
        @include font-size(16px);
    }
}
.hero-slider__scroll-message {
    /*
    @include font-size(10px);
    bottom:-20px;
    color:black;
    */
    display:none;   // hide now on mobile

    text-transform:uppercase;
    position:absolute;
    right:20px;

    @include breakpoint(tablet-v) {
        display:block;
        @include font-size(12px);
        bottom:5px;
        color:white;
    }
}
