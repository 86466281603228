
// ----------------------------------------------------------------------------
// Helper classes
// ----------------------------------------------------------------------------

/*
 * Hide visually and from screen readers:
 */

.is-hidden {
    display: none !important;
}

.is-transparent {
    opacity:0 !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.is-visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.is-visuallyhidden.focusable:active,
.is-visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.is-invisible {
    visibility: hidden;
}

